import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isSetup = sessionStorage.getItem("isSetup") === "true" ? true : false;
  const isLogin = sessionStorage.getItem("user_info") ? true : false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogin) {
          if (!isSetup) {
            // setup not done
            return (
              <Redirect
                to={{ pathname: "/setup", state: { from: props.location } }}
              />
            );
          }
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
