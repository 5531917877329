/******************************************************************************
 *
 * Quantropi CONFIDENTIAL
 * ----------------------
 *
 * This file is proprietary code and subject to the terms and conditions
 * defined in the file 'LICENSE', which is part of this source code package.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Quantropi Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Quantropi Incorporated and its suppliers and
 * may be covered by Canadian, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Quantropi Incorporated.
 *
 *****************************************************************************/

const colors = [
  "#20a8d8",
  "#d88820",
  "#d82c20",
  "#4dbd74",
  "#bd8e4d",
  "#604dbd",
  "#aabd4d",
  "#bd4daa",
  "#3920d8",
  "#d820bf",
];

var c_id = -1;
// simple color wheel rotation
const NextColor = () => {
  c_id += 1;
  if (c_id >= colors.length) c_id = 0;
  return colors[c_id];
};

const RandColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export { NextColor, RandColor };
