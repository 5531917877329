/******************************************************************************
 *
 * Quantropi CONFIDENTIAL
 * ----------------------
 *
 * This file is proprietary code and subject to the terms and conditions
 * defined in the file 'LICENSE', which is part of this source code package.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Quantropi Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Quantropi Incorporated and its suppliers and
 * may be covered by Canadian, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Quantropi Incorporated.
 *
 *****************************************************************************/
import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";
import logo_ani from "./Q_logo_ani.gif";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilPeople,
  cilBuilding,
  cilDevices,
  cilSun,
  cilMoon,
  cilUser,
  cilLockLocked,
  cilPencil,
  cilTrash,
  cilLockUnlocked,
  cilPlus,
  cilCheck,
  cilSettings,
  cilClipboard,
  cilChevronRight,
  cilChevronLeft,
  cilChevronTop,
  cilChevronBottom,
  cilSatelite,
  cilPhone,
  cilX,
  cilBell,
  cilBellExclamation,
  cilSpreadsheet,
  cilPrint,
  cilArrowThickFromLeft,
  cilArrowRight,
  cilArrowLeft,
  cilSearch,
  cilGroup,
  cilAccountLogout,
  cilArrowThickToBottom,
  cilClone,
  cilFilter,
  cilFilterX,
  cilCode,
  cilLibrary,
  cilCenterFocus,
  cilChart,
  cilDescription,
} from "@coreui/icons";

import {
  cilHandshake,
  cilInfoCircle,
  cilExclamationCircle,
  cilKey,
  cilMail,
  cilTimeline,
  cilSecurity,
  cilCaretBottom,
  cisCaretBottom,
} from "@coreui/icons-pro";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
    logo_ani,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilPeople,
    cilBuilding,
    cilDevices,
    cilSun,
    cilMoon,
    cilUser,
    cilLockLocked,
    cilPencil,
    cilTrash,
    cilLockUnlocked,
    cilPlus,
    cilCheck,
    cilSettings,
    cilClipboard,
    cilChevronRight,
    cilChevronLeft,
    cilChevronTop,
    cilChevronBottom,
    cilSatelite,
    cilPhone,
    cilX,
    cilBell,
    cilBellExclamation,
    cilSpreadsheet,
    cilPrint,
    cilArrowThickFromLeft,
    cilArrowRight,
    cilArrowLeft,
    cilSearch,
    cilGroup,
    cilAccountLogout,
    cilArrowThickToBottom,
    cilClone,
    cilFilter,
    cilFilterX,
    cilCode,
    cilLibrary,
    cilCenterFocus,
    cilChart,
    cilDescription,
  },
  {
    cilHandshake,
    cilInfoCircle,
    cilExclamationCircle,
    cilKey,
    cilMail,
    cilTimeline,
    cilSecurity,
    cilCaretBottom,
    cisCaretBottom,
  }
);
