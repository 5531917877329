/******************************************************************************
 *
 * Quantropi CONFIDENTIAL
 * ----------------------
 *
 * This file is proprietary code and subject to the terms and conditions
 * defined in the file 'LICENSE', which is part of this source code package.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Quantropi Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Quantropi Incorporated and its suppliers and
 * may be covered by Canadian, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Quantropi Incorporated.
 *
 *****************************************************************************/
import { createStore } from "redux";
import { RandColor } from "./_helpers/ColorWheel";
import API from "./services/Api";

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  user_info: JSON.parse(sessionStorage.getItem("user_info")),
  user_bg: RandColor(),
  statics: {},
  CSRFToken: "",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

// load the remote statics into the intial default store
API.get("/statics").then((response) => {
  initialState["statics"] = response.data;
  //console.log(response.data)
});

const store = createStore(changeState);
export default store;
